import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter as Router, Routes,Route} from "react-router-dom";
import { Hero,  SectionHeader, FeaturesTiles  } from './index';
import { userGuideData, userGuideHeader } from './EditorUserGuide/userGuide';


export const EditorUserGuide = ()=>{

return(
        <>
              <Hero className="illustration-section-01" />
              
              <FeaturesTiles data={userGuideData} header={userGuideHeader}/>
              

        </>
    )

  }


  //import { FeaturesSplit } from '../components/sections/FeaturesSplit';
  