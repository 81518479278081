import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter as Router, Routes,Route} from "react-router-dom";
import { Hero,  SectionHeader, FeaturesTiles  } from './index';
import { blogData, blogHeader } from './EditorBlog/editorBlog';

export const EditorBlog = ()=>{

return(
        <>
              <Hero className="illustration-section-01" />
              
              <FeaturesTiles data={blogData} header={blogHeader}/>

        </>
    )

  }