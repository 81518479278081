import React from 'react';
import ReactDOM from 'react-dom';
import { Hero, Cta, SectionHeader } from './index';
import {BrowserRouter as Router, Routes,Route} from "react-router-dom";



const sectionHeader = {
    title: 'Privacy and Cookie Policy',
    paragraph: `
    Last updated: September 1, 2020
    
    Thank you for choosing to be part of our community at , and using our brand Modern Posts 365 Designers Edition (“Cohorts Software Applications Inc.”,“Cohorts”, “Modern Posts 365 Designers Edition”,“MP365DE” ,“we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy.  
    When you visit our website https://www.Cohorts.app, and use any of our services or apps, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our sites and our services. 
    This privacy notice applies to all information collected through our website (such as https://www.Cohorts.app), and/or any related services, apps, sales, marketing or events (we refer to them collectively in this privacy notice as the "Services"), unless explicitly stated otherwise.  
    Please read this privacy notice carefully as it will help you make informed decisions about sharing your personal information with us.   
    This Privacy Policy describes Modern Posts 365 Designers Edition (hereafter MP365DE) provided by Cohorts Software Applications Inc (hereafter Cohorts) practices regarding the collection, use and disclosure of the information Cohorts collects from you when you use Custom Stickers Extension in Microsoft Teams Application. The data you transfer to or our server and any information you provide for this purpose, is a serious responsibility, and this is how will be handing it. By accessing or using our Extension, you agree to this Privacy Policy.
    
    WHAT INFORMATION DO WE COLLECT?
    When you use the app known as MP365DE in Microsoft Teams, or Microsoft Office 365, or Microsoft Dynamics 365 we also retrieve the email address you use to sign into Microsoft’s services including Microsoft Graph. This is used solely to enable you to access your own data from the Microsoft Teams, and to be able to post messages to Micrososft Teams.
    MP365DE does not collect, process or store any personal information, including your user name, name and email address or non-personal information from you.
    We do not use cookies, but third party services such as Microsoft Teams, Microsoft app store, Micrososft Graph, Micrososft Azure, and Microsof Azure AD , may do so.    
    By using MP365DE the user agrees to any applicable Privacy Policy published by Microsoft Teams, Microsoft Office 365 (M365), Micrososft Appstore, Microsoft Graph and any other applicable Microsoft product used.  
    Cohorts has access to collected information, and usage stats as regularily provided to a publisher in this app store. 
    
    HOW DO WE USE YOUR INFORMATION?  
    MP365DE posts on your behalf visually designed messages you composed to Microsoft Teams at the Teams and Channel this app is hosted.
    MP365DE does not store the messages at any service under the control of MP365DE or Cohorts, It simply send the message from the browser directly to MS Teams using Micrososft endpoint with Microsoft Graph Application Program Interface (API), secured by Microsof Azure Active Directory (Azure AD).
    
    WILL YOUR INFORMATION BE SHARED WITH ANYONE?  
    We do not collect information, but information is being colelcted by third party such Micrososft app store, and we have access to that information as a regular publisher in that platform.
    We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
    We may process or share data based on the following legal basis:
    
    Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose. 
    Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests. 
    Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract. 
    Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
    Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
    
    More specifically, we may need to process your data or share your personal information in the following situations:   
    Vendors, Consultants and Other Third-Party Service Providers. 
    We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. 
    
    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    We may use cookies and other tracking technologies to collect and store your information. 
    As of update of this this Privacy, we do not.
    Third party providers, such Micrososft may do so.
    Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.     
    
    DO WE COLLECT INFORMATION FROM MINORS?  
    Same Policy is applied to all users of Microsoft Teams and we do not knowingly collect data from or market to children under 18 years of age. 
    In case of Micrososft Teams for Education is used, this Policy is applied to students of all ages save and except sharing data that will require consent of Guardian.
    
    
    WHAT ARE YOUR PRIVACY RIGHTS?  
    In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your granted permissions at any time.   
    In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the details provided below. We will consider and act upon any request in accordance with applicable data protection laws. 
    If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
    If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.      
    
    
    DATA SECURITY
    The data is protected in Microsoft Azure authentication and authorization services and stored in Teams other Micrososft services such as Micrososft App Store. 
    Some information is temporarily stored in the end user browser local strorage by MP365DE application, and can be cleared by the user when choosing Revoke Permissions in the Account Tab.
    
    CHANGES TO OUR PRIVACY STATEMENT
    Cohorts may change the Privacy Statement from time to time at Cohorts sole discretion as well as as necessary to stay compliant with relevant laws. The most updated version of the Policy will be published under the Account tab of MP365DE application.
    It is users responsibility to check the last updated date in the Account Tab of the application.
    We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.  
    
    DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?  
    Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.   
    California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request by email and request a receipt of delivery as a proof of delivery. 
    If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California.
    
    CONTACT INFORMATION
    Questions regarding MP365DE Privacy Statement or information practices should be directed to info@cohorts.app
      `
  };

export const EditorPrivacy = ()=>{

return(
        <>
              <Hero className="illustration-section-01" />
              
              <SectionHeader data={sectionHeader} className="left-content" />
    
        </>
    )
}