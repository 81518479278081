import React from 'react';
// import sections
import { Hero } from '../components/sections/Hero';
import { FeaturesTiles } from '../components/sections/FeaturesTiles';
import { Cta } from '../components/sections/Cta';
import { featureData, featureHeader } from './feature';


export const Home = () => {
  
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles data={featureData} header={featureHeader}/>
      
      <Cta split />
    </>
  );
}

