import React from 'react';
import ReactDOM from 'react-dom';
import { Home } from './views/Home';
import {BrowserRouter as Router, Routes,Route} from "react-router-dom";

export const NotelettesLandingPage = ()=>{

return(
        <div>
            
            <Home />
        </div>
    )
}