export const userGuideData = [
  { title: "Step 1: Login.", content: "" , img: "feature-tile-icon-01.svg"},
  { title: "Step 2: Browse and Select Messages from Channels or Chats tabs.", content: "" , img: "feature-tile-icon-02.svg"},
  { title: "Step 3: Choose from Selected Items.", content: "",  img: "feature-tile-icon-01.svg"},
  { title: "Step 4: Edit Email Content.", content: "" , img: "feature-tile-icon-01.svg"},
  { title: "Step 5: Edit Title and Recepients and Send Email", content: "" , img: "feature-tile-icon-02.svg"},
  { title: "How-to Select a Message?", content: "Just point on the icon and it will push the post to the Selected Items",  img: "feature-tile-icon-01.svg"},
  { title: "How-to Select all shown Messages?", content: "On the Channel or Chat row select all messages. It will push them without their replies",  img: "feature-tile-icon-01.svg"},
  { title: "How-to select a Post and all its Replies?", content: "On the Message row in Channels select message and replies. No replies available in Chats",  img: "feature-tile-icon-01.svg"},
  { title: "How-to sort the Messages?", content: "This feature is not available per limitation of Teams API",  img: "feature-tile-icon-01.svg"},
  { title: "How-to use the Submit button?", content: "In every Tab, the submit button takes you to the next tab with current selection or content",  img: "feature-tile-icon-01.svg"},
  { title: "How-to send the Email?", content: "Just click on Send, it will send from your online account directly.",  img: "feature-tile-icon-01.svg"},
  { title: "How-to send to more than one receipients?", content: "Simply add more than one email, yet eparate with ; (semicolon)",  img: "feature-tile-icon-01.svg"},
  { title: "How-to update the messages list in the Reader Explorer?", content: "Simply click on Refresh.",  img: "feature-tile-icon-01.svg"},
  { title: "How-to logout?", content: "Simply by clicking on Logout at the Account tab.",  img: "feature-tile-icon-01.svg"},
  
];

export const userGuideHeader = {
  title: "User Guide",
  paragraph:
    "How to use the application",
};
