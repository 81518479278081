export const featureData = [
  { title: "Easy", content: "Simple four steps: Grant permissions, Select palettes, Select width, compose a post and Send it directly from the app." , img: "feature-tile-icon-01.svg"},
  { title: "Colorful", content: "Based on designers selection of color palettes. You don't need to start from scratch" , img: "feature-tile-icon-02.svg"},
  { title: "Expressive", content: "Use color palettes that express your color set, your emotion, mood, and yourself.",  img: "feature-tile-icon-01.svg"},
  { title: "Impressive", content: "Choose a color palettes that impress other aprticipants of in Teams channel conversation" , img: "feature-tile-icon-01.svg"},
  { title: "Useful", content: "Separates the initial conversation post from the replies, as a subject matter" , img: "feature-tile-icon-02.svg"}
];

export const featureHeader = {
  title: "Provides Designers Color Palette based templates for writing Posts to Microsoft Teams",
  paragraph:
    "Provides a gallery of several palettes as background templates for posts to MS Teams. Users can choose the color template and the width of the post in full width or regular(partial).  Color Posts provide personal touch to posts as a visual expression of the user.",
};
