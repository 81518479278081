import React from 'react';
// import sections
import { Hero } from '../components/sections/Hero';
import { FeaturesTiles } from '../components/sections/FeaturesTiles';
import { FeaturesSplit } from '../components/sections/FeaturesSplit';
import { Testimonial } from '../components/sections/Testimonial';
import { Cta } from '../components/sections/Cta';
import { featureData, featureHeader } from './feature';
import Logo from '../components/layout/partials/Logo';
import { Footer } from '../components/layout/Footer';

const Home = () => {
  
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles data={featureData} header={featureHeader}/>
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
     
      <Cta split />
      <Footer />
      
    </>
  );
}

export default Home;