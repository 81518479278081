import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NotelettesLandingPage } from "./notelettes/NotelettesLandingPage";


import { EditorAbout } from "./editor/EditorAbout";
import { EditorApp } from "./editor/EditorApp";
import { EditorLandingPage } from "./editor/EditorLandingPage";
import { EditorPrivacy } from "./editor/EditorPrivacy";
import { EditorSupport } from "./editor/EditorSupport";
import { EditorTerms } from "./editor/EditorTerms";
import { EditorUserGuide } from "./editor/EditorUserGuide";
import { EditorBlog } from "./editor/EditorBlog";

//Reader
import { ReaderLandingPage } from "./reader/ReaderLandingPage";
import { ReaderLogin } from "./reader/ReaderLogin";
import { ReaderPrivacy } from "./reader/ReaderPrivacy";
import { ReaderSupport } from "./reader/ReaderSupport";
import { ReaderTerms } from "./reader/ReaderTerms";
import { ReaderUserGuide } from "./reader/ReaderUserGuide";
import { ReaderAbout } from "./reader/ReaderAbout";
import { ReaderBlog } from "./reader/ReaderBlog";
import { ReaderApp } from "./reader/ReaderApp";

//Search
import { SearchAbout } from "./search/SearchAbout";
import { SearchApp } from "./search/SearchApp";
import { SearchBlog } from "./search/SearchBlog";
import { SearchLandingPage } from "./search/SearchLandingPage";
import { SearchLogin } from "./search/SearchLogin";
import { SearchPrivacy } from "./search/SearchPrivacy";
import { SearchSupport } from "./search/SearchSupport";
import { SearchTerms } from "./search/SearchTerms";


//Todo
import { TodoAbout } from "./todo/TodoAbout";
import { TodoApp } from "./todo/TodoApp";
import { TodoBlog } from "./todo/TodoBlog";
import { TodoLandingPage } from "./todo/TodoLandingPage";
import { TodoPrivacy } from "./todo/TodoPrivacy";
import { TodoSupport } from "./todo/TodoSupport";
import { TodoTerms } from "./todo/TodoTerms";












export const RootRouter =() => {

  return (
      <Router  >
        <Routes>
          <Route path="/" element={<NotelettesLandingPage />} />

          <Route path="Reader" element={<ReaderLandingPage />} >
                <Route path="/" element={<ReaderAbout />} />
                <Route path="About" element={<ReaderAbout />} />
                <Route path="Terms" element={<ReaderTerms />} />
                <Route path="Privacy" element={<ReaderPrivacy />} />
                <Route path="Support" element={<ReaderSupport />} />
                <Route path="Blog" element={<ReaderBlog />} />
                <Route path="App" element={<ReaderApp />} />
                <Route path="Login" element={<ReaderLogin />} />
                <Route path="UserGuide" element={<ReaderUserGuide />} />
          </Route>

          <Route path="Editor" element={<EditorLandingPage />} >
                <Route path="/" element={<EditorAbout />} />
                <Route path="About" element={<EditorAbout />} />
                <Route path="Terms" element={<EditorTerms />} />
                <Route path="Privacy" element={<EditorPrivacy />} />
                <Route path="Support" element={<EditorSupport />} />
                <Route path="App" element={<EditorApp />} />
                <Route path="UserGuide" element={<EditorUserGuide />} />
                <Route path="Blog" element={<EditorBlog />} />
          </Route>


            <Route path="Search" element={<SearchLandingPage />} >
                <Route path="/" element={<SearchAbout />} />
                <Route path="About" element={<SearchAbout />} />
                <Route path="Terms" element={<SearchTerms />} />
                <Route path="Privacy" element={<SearchPrivacy />} />
                <Route path="Support" element={<SearchSupport />} />
                <Route path="Blog" element={<SearchBlog />} />
                <Route path="App" element={<SearchApp />} />
                <Route path="Login" element={<SearchLogin />} />
            </Route>

            <Route path="Todo" element={<TodoLandingPage />} >
                <Route path="/" element={<TodoAbout />} />
                <Route path="About" element={<TodoAbout />} />
                <Route path="Terms" element={<TodoTerms />} />
                <Route path="Privacy" element={<TodoPrivacy />} />
                <Route path="Support" element={<TodoSupport />} />
                <Route path="Blog" element={<TodoBlog />} />
                <Route path="App" element={<TodoApp />} />
                <Route path="Login" element={<SearchLogin />} />
            </Route>

      </Routes>
    </Router>
  )
  }
