import React from 'react';
import ReactDOM from 'react-dom';

import { IndexReaderAbout } from "./ReaderAbout/src/IndexReaderAbout";

export const ReaderAbout = ()=>{

return(
    <IndexReaderAbout />
    )
}