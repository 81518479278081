export const blogData = [
  { title: "What is the integration with Teams?", content: "It can be used as a web application, a teams application with the personal tabs, or as a web part in SharePoint Online" , img: "feature-tile-icon-01.svg"},
  { title: "Do I have to install the app?", content: "No. But if you want to use it as Teams app you would need to install a configuration file" , img: "feature-tile-icon-02.svg"},
 // { title: "Teams App Manifest File download link:", content: `https://gilstavdev-my.sharepoint.com/:u:/g/personal/admin_gilstavdev_onmicrosoft_com/EXMToemf6Y1EibPHOzc-tSkBffSBUHGpJptIejVJG0x89w?e=H3S0mB` , img: "feature-tile-icon-02.svg"},
  { title: "What is the cost for using the app?", content: `Payment is by donation. https://www.buymeacoffee.com/6WONjZKSz`,  img: "feature-tile-icon-01.svg"},
  { title: "Can I request customizations or enhancements", content: "Yes, open for professional services." , img: "feature-tile-icon-01.svg"},
  { title: "How do I change the header and footer of the email?", content: "Settings Tab allows customizing the header or footer" , img: "feature-tile-icon-02.svg"},
  { title: "Does the content goes through Cohorts application server?", content: "No, firstly the application is loaded to the browser and then in communicates directly with Microsoft 365, not data transfer goes through Cohorts servers.",  img: "feature-tile-icon-01.svg"},
  { title: "What is the sort order of the Teams Posts?", content: "The sort order of the posts is by date. Yet a post that any of its replies was added or changed is considered as updated at the same time.",  img: "feature-tile-icon-01.svg"},
  { title: "What are the permissions requested when login?", content: "user.read, Mail.Send, Team.ReadBasic.All, Channel.ReadBasic.All, ChannelMessage.Read.All",  img: "feature-tile-icon-01.svg"},
  { title: "Does the Tenant Admin need to confirm the above permisions [scopes]?", content: "Yes in AD Admin Portal",  img: "feature-tile-icon-01.svg"}, 
//  { title: "What is the setup for App Registration?", content: "MSAL 2.0, Implicit Grant, SPA . Download link : https://1drv.ms/u/s!AuYpEGleNMDscahOisWfYjEUqw4?e=guyxtf",  img: "feature-tile-icon-01.svg"}, 
  { title: "Can the application be loaded by the end user into Teams?", content: "Yes, It is called side loading. Te end user can upload a configuration file.",  img: "feature-tile-icon-01.svg"},
  { title: "Can the application be hosted within the customer's tenant?", content: "Yes, it is available for purchase.",  img: "feature-tile-icon-01.svg"},
  { title: "Can the application be hosted as a SharePoint online web part?", content: "Yes, a specific version could be purchased.",  img: "feature-tile-icon-01.svg"},
  { title: "Can I save the Settings configuration from one browser session to another?", content: "This feature is not available in this version. It could be available per request.",  img: "feature-tile-icon-01.svg"},
  { title: "Which email account is used for sendit the email?", content: "It uses the email account of the logged in user directly. No need to open an email application",  img: "feature-tile-icon-01.svg"},
  { title: "How would I upgrade the application later?", content: "The application is on-demand and is upgraded at the service level, no special upgrade would be needed.",  img: "feature-tile-icon-01.svg"},
  { title: "How do I report on a bug, or suggest an improvement to the product?", content: "Send it by email to Cohorts@outlook.com",  img: "feature-tile-icon-01.svg"},
  { title: "What are the limitations?", content: "Per the given API capacity, sorting, paging, search is limited.",  img: "feature-tile-icon-01.svg"},
  { title: "Why the product is in Beta mode?", content: "For two major reasons, one is that it is new, scond it is based on MS technologirs which are currently at Beta version.",  img: "feature-tile-icon-01.svg"}
];

export const blogHeader = {
  title: "Blog of Q&A",
  paragraph:
    "Points of Clarifcation",
};
