export const featureData = [
  { title: "Cohorts Reader", content: "Collect Posts, Replies and Chat Messages to one Email and Send" , img: "feature-tile-icon-01.svg", productLink: "https://www.cohorts.app/reader"},
  // { title: "Modern Todo 365 Excel Edition", content: "Manage you tasks and save them in an online Excel file" , img: "feature-tile-icon-02.svg", productLink:"https://todo-365-excel-cohots.netlify.app"}
  { title: "Cohorts Postcards - Designers Color Palettes Edition", content: "Provides Designers Color Palette based templates for writing posts to Micrososft Teams." , img: "feature-tile-icon-02.svg", productLink:"https://cohorts.app/editor/about"}
  
];

export const featureHeader = {
  title: "Easy to use apps that improve your modern workflow.",
  paragraph:
    "",
};
