import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter as Router, Routes,Route} from "react-router-dom";
import { Hero,  SectionHeader, FeaturesTiles  } from './index';
import { userGuideData, userGuideHeader } from './ReaderUserGuide/userGuide';

export const ReaderUserGuide = ()=>{

return(
        <>
              <Hero className="illustration-section-01" />
              
              <FeaturesTiles data={userGuideData} header={userGuideHeader}/>

        </>
    )

  }