import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from './App';
//import * as serviceWorker from './serviceWorker';
import Home from './views/Home';

//import './App.css';
import './assets/scss/style.scss';

const history = createBrowserHistory();

export const IndexReaderAbout = ()=>{

return (

  <Home />
)

  // return(
  //     <Router history={history}>
  //       <App />
  //     </Router>
  //   )

}
