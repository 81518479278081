import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter as Router, Routes,Route, Outlet} from "react-router-dom";

export const ReaderLandingPage = () => {
  return (
  <div>
    <Outlet />
  </div>
  )
};