export const userGuideData = [
  { title: "Step 1: Grant Pemissions.", content: "Grant Pemissions to the app to post a message to Teams Channel on your behalf." , img: "feature-tile-icon-01.svg"},
  { title: "Step 2: Select a Color Palette.", content: "Select a Color Palette , one of many available." , img: "feature-tile-icon-02.svg"},
  { title: "Step 3: Select Width.", content: "Full width or partial.",  img: "feature-tile-icon-01.svg"},
  { title: "Step 4: Write.", content: "Write your Message and Post it." , img: "feature-tile-icon-01.svg"},
  { title: "Step 5: Check.", content: "Check the current Teams Channel new Conversation as created with your Post," , img: "feature-tile-icon-02.svg"}
];

export const userGuideHeader = {
  title: "User's Guide",
  paragraph:
    "How to use the application",
};
