import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter as Router, Routes,Route} from "react-router-dom";

export const SearchTerms = ()=>{

return(
        <div>
            <h1>Welcome to Search Terms Page!</h1>
        </div>
    )
}