import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import ButtonGroup from '../../elements/ButtonGroup';
import Button from '../../elements/Button';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
export const TileItem = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  title,
  content,
  img,
  productLink,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  
  return (
    <div className="tiles-item reveal-from-bottom">
      <div className="tiles-item-inner">
        <div className="features-tiles-item-header">
          <div className="features-tiles-item-image mb-16">
            <Image
              src={require(`./../../../assets/images/${img}`)}
              alt="Features tile icon 01"
              width={64}
              height={64}
            />
          </div>
        </div>
        <div className="features-tiles-item-content">
          <h4 className="mt-0 mb-8">{title}</h4>
          <p className="m-0 text-sm">{content}</p>
        </div>
        <br />
        <ButtonGroup>
         
          <Button
            tag="a"
            color="primary"
            wideMobile
            href={productLink}
          >
            Try it
          </Button>
        </ButtonGroup>
      </div>
      <br />
    </div>
  );
}

TileItem.propTypes = propTypes;
TileItem.defaultProps = defaultProps;

