import React from 'react';
import ReactDOM from 'react-dom';
import { Hero, Cta, SectionHeader } from './index';
import {BrowserRouter as Router, Routes,Route} from "react-router-dom";



const sectionHeader = {
    title: 'Privacy and Cookie Policy',
    paragraph: `Your use of Cohorts Products & Services, including Open Source Software and third party software , is subject to these Cookie and Privacy policies and terms. 
    By using this site and or Modern Reader 365 Teams Edition application, you acknowledge that you have read , understand and agreed to the Cookie Policy and Cache and Privacy Policy
    
    Cookie Policy and Cache:
    
    To know about what browser cookies are you may want to read content at https://www.pcmag.com/encyclopedia/term/cookie. 
    To know about what browser cache you may want to read content at https://www.pcmag.com/encyclopedia/term/browser-cache
    
    This site and the applications described in it may use cookies and browser cache to gather analytics data that helps us improve our website and the applications or to enable specific features of the application r. 
    
    The cookies and cache items can be inspected by yourself by using Dev Tools in your browser. You can clear your cookies and cache of your browser should you want to remove related cookies and chache
    https://us.norton.com/internetsecurity-privacy-how-to-clear-cookies.html
    
    
    Privacy Policy:
    
    Modern Reader 365 application does not keep personal data of the user. 
    
    Modern Reader 365 application logs in to Microsoft 365  applications and eco systems on your behalf and assuume the following permissions on your behalf:
    scopes: ["user.read", "Mail.Send","Team.ReadBasic.All","Chat.Read","Chat.ReadBasic","Chat.ReadWrite"]
    
    Other vendors Like Microsoft might track the use of the application on behalf of the loggged in account of the user. 
    
    
    Third Party providers:
    
    Modern Reader 365 relies on Micrososft Graph API , By using this application the user agrees to  Microsoft APIs Terms of Use. 
    See Micrososft https://docs.microsoft.com/en-us/legal/microsoft-apis/terms-of-use 
    
    Other third party providers might track the usage of their code / application / software library.
    Other third party providers include but not limited to the providers of the following apps , code libraries or code sections and the use of their code is subject to bothe their policies and their dependencies as well.
        "@material-ui/core": "^4.9.0",
        "@microsoft/mgt": "^1.3.0-preview.3",
        "@microsoft/microsoft-graph-client": "^1.7.0",
        "@microsoft/sp-core-library": "1.8.2",
        "@microsoft/sp-lodash-subset": "1.8.2",
        "@microsoft/sp-office-ui-fabric-core": "^1.8.2",
        "@pnp/common": "^1.3.3",
        "@pnp/logging": "^1.3.3",
        "@pnp/odata": "^1.3.3",
        "@uifabric/icons": "7.1.1",
        "@uifabric/react-cards": "^0.108.2",
        "@uifabric/react-hooks": "^7.0.3",
        "@uifabric/styling": "^7.7.2",
        "draft-js": "^0.11.4",
        "draftjs-to-html": "^0.9.1",
        "html-react-parser": "^0.10.1",
        "html-to-draftjs": "^1.5.0",
        "loglevel": "^1.6.8",
        "msal": "^1.1.3",
        "office-ui-fabric-react": "^7.80.0",
        "react": "^16.8.6",
        "react-dom": "^16.8.6",
        "react-draft-wysiwyg": "^1.14.4",
        "react-error-boundary": "^1.2.5",
        "react-scripts": "^3.3.0",
        "sanitize-html": "^1.26.0",
        "slate": "^0.57.1",
        "slate-react": "^0.57.1",
        "use-error-boundary": "1.2.3",
        "uuid": "3.4.0"
    
        The above list include thirds party code that relies on other dependent third party code. 
    
        Information about the above applications or code vendors and their dependent 3rd party tools/ code / apps / resources can be retrieved from NPM  - Node package manager, and npm Registry at https://www.npmjs.com/
      `
  };

export const ReaderPrivacy = ()=>{

return(
        <>
              <Hero className="illustration-section-01" />
              
              <SectionHeader data={sectionHeader} className="left-content" />
    
        </>
    )
}