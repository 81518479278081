export const featureData = [
  { title: "Easy", content: "Simple four steps: Explore posts, Select the ones you need, Author the email and Send it directly from the app." , img: "feature-tile-icon-01.svg"},
  { title: "Useful", content: "Saves time in finding, copying saving and editing posts into one summary email that makes sense." , img: "feature-tile-icon-02.svg"},
  { title: "Private", content: "App does not keep any users information. Login is with Microsoft account directly with Microsoft Teams login credentials. Access is granted to application by the user only to a limited permission required.",  img: "feature-tile-icon-01.svg"},
  { title: "Wed App", content: "Application can be used as a stand alone web application that connects to Teams without any installation in Teams." , img: "feature-tile-icon-01.svg"},
  { title: "Team App", content: "Application can be used as a Teams user application , easily accessible from the left navigation bar in Teams" , img: "feature-tile-icon-02.svg"},
  { title: "Robust", content: "Lists all teams of membership, and dynamically unfolds Channels, posts and replies. Enable multiple selections. Advanced web based editor allows text and graphics curation.",  img: "feature-tile-icon-01.svg"}
];

export const featureHeader = {
  title: "Collects Selected Channel and Chat Messages to One Email",
  paragraph:
    "Explore in a dynamic view messages and replies within Teams Channels and Teams Chats. Select to keep the ones needed for the meeting summary. Manage the selected posts in the application.  Assemble just the ones you want to an online editor. Edit the content, add any additional notes and comments. Email the curated content.",
};
