import React from 'react';
import ReactDOM from 'react-dom';

import { IndexEditorAbout } from "./EditorAbout/src/IndexEditorAbout";

export const EditorAbout = ()=>{

return(
    <IndexEditorAbout />
    )
}