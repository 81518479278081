import React from 'react';
import ReactDOM from 'react-dom';


import { Hero,  SectionHeader } from './index';
import {BrowserRouter as Router, Routes,Route} from "react-router-dom";
import { Cta } from './EditorSupport/SupportCta';
const sectionHeader = {
    title: 'Support',
    paragraph: `Service Request for Modern Designers Posts 365 Color Palettes Edition is provided by email.`
  };

export const EditorSupport = ()=>{
    return(
        <>
            <Hero className="illustration-section-01" />
            <SectionHeader data={sectionHeader} className="center-content" />
            <Cta split />
        </>
    )
}





