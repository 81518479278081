import React from 'react';
import ReactDOM from 'react-dom';

import { Hero, Cta, SectionHeader } from './index';

import {BrowserRouter as Router, Routes,Route} from "react-router-dom";

const sectionHeader = {
    title: 'Terms and Conditions of Use',
    paragraph: `Last updated: September 1, 2020

Thank you for choosing to be part of our community at , and using our brand Modern Posts 365 Designers Edition  . We are committed to protecting your personal information and your right to privacy.  

For purposes of these Terms:
-  “Cohorts Software Applications Inc.”,“Cohorts”,“we”, “us”, or “our”  means Cohorts Software Applications Inc. company registered in British Columbia, Canada
-  “Modern Posts 365 Designers Edition”,“MP365DE” , means Modern Posts 365 Designers Edition application as published in Microssoft App Store for Micrososft Teams.
-  “Apps”,“Our Services” ,means any application. apps, website or services provided by Cohorts Software Applications Inc.
- “Content” means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through Our Services; and 
-“User Content” means any Content that users (including you) provide to be made available through Our Services. 


These Terms of Use are a contract between you and Cohorts Software Applications for the use of our web site at (http or https)://cohorts.app and/or any related apps and/or any related services or our services  . 
By using Our Services on the web or from any other applications that give you access to Our Services (such as from Microsoft Teams, Microsoft Office 365, or Microsoft Dynamics 365), you agree to the following Terms of Use.
If you use the MP365DE, visit our website https://www.Cohorts.app, or use any of our applications such as “Modern Posts 365 Designers Edition”, you will be bound by the following Terms and Conditions of Use.


If you violate any of the terms outlined below, we reserve the right to cancel accounts or bar access to accounts without notice. If you do not agree to these terms, please do not use Our Services.
The mark Cohorts.app and the Cohorts logo are trademarks of Cohorts Software Applications.


ACCOUNT TERMS
You may use Our Services only if you are 18 years or older and capable of forming a binding contract with Cohorts Software Applications Inc., and are not barred from using Our Services under applicable law.
You must be a human. Accounts registered by “bots” or other automated methods are not permitted.
You are responsible for maintaining the privacy and security of your account. Cohorts Software Applications Inc. will not be held liable for any damage or loss that may result from your failure to protect your login information, including your password.
Cohorts Software Applications Inc. may communicate with you via email, in-app messages or pushed notifications regarding your account, system updates, or other issues related to your account.
You are responsible for all activity that occurs under your account. You may not use Our Services for any illegal or unauthorized purpose. You must not, in the use of Our Services, violate any laws in your jurisdiction (including but not limited to copyright laws).
Cohorts Software Applications Inc. may refuse service to anyone for any reason at any time, and may terminate use of MP365DE or anny of the its apps at its own discretion.  

REGISTRATION
No user registration is required to use MP365DE and Cohorts accordingly do not require you to provide any information for this purpose.
The operation of MP365DE is embeded within the context of MS Teams. 
Custoemr will be require to extend the permission to allow MP365DE to post messahes in MS Teams on his/hers behalf.


CHARGE
The cost of using the MP365DE is as provided by Microsoft App store.

VERSION CHECK
MP365DE  will be updated automatically.

ACCEPTABLE USE POLICIES
When using Our Services, applications, including MP365DE you may not:

Use Our Services in a way that is against applicable law, including:
Illegal activity such as child pornography; gambling; piracy; violating copyright, trademark or other intellectual property laws.
Accessing or authorizing anyone to access Our Services from an embargoed country.
Threatening, stalking, defaming, defrauding, degrading, victimizing or intimidating anyone for any reason.
Invading anyone's privacy by attempting to harvest, collect, store, or publish private or personally identifiable information, such as passwords, account information, credit card numbers, addresses, or other contact information without their knowledge and consent.

Use Our Services in a way that could harm them or impair anyone else's use of them. Including:
-Any attempt to gain unauthorized access to Our Services, acting to deny others access to Our Services, or authorizing any third party to access or use Our Services on your behalf (such as anyone without a license or revealing to anyone your username and password).
-Use Our Services to try to gain unauthorized access to any other service, data, account or network by any means.
-Use any automated process or service to access or use Our Services such as a BOT, a spider or periodic caching of information provided by us.
-Intending to harm or exploit minors in any way or collecting personally identifiable information of any minor.

Falsify any email header information or in any way misrepresent your identity.
-Including misrepresenting the source of anything you post or upload or impersonating another individual or entity, such as with "spoofing". 
-Use Our Services to transmit, distribute, or deliver any unsolicited bulk or unsolicited commercial e-mail or other communications (i.e., spam).
-Remove, modify, or tamper with any regulatory or legal notice or link that is incorporated into Our Services.
-Including providing or creating links to external sites that violate this policy or other legal agreements that Cohorts Software Applications Inc. provides.
-As well as any use of Our Services to distribute any offering or link designed to violate these terms (e.g., enable sending of spam, enable denial of service attacks, etc.)

Additionally:
-Cohorts Software Applications Inc. is not responsible for the content of any user-created posting, listing or message. The decision to view content or engage with others is yours. We advise you to use your judgment.
-You are responsible for protecting your computer against interference, spyware, or viruses that may be encountered for downloaded items from Our Services. We recommend you install a virus protection program on your computer and keep it up to date.
-Information you provide or upload to Our Services may be stored outside of the country in which you reside.

If Cohorts Software Applications Inc. believes that unauthorized or improper use is being made of Our Services, it may, without notice and at its sole discretion, take such action as it deems appropriate, including blocking messages from a particular internet domain, mail server or IP address. 

Violation of these Acceptable Use policies can lead to termination of your account.

Cohorts Software Applications Inc. reserves the right to amend or change these Acceptable Use policies for Our Services at any time without notice. We encourage you to periodically review these policies to ensure you are in compliance.

Nothing in these policies is intended to grant any rights in Our Services. Failure to enforce this policy in every instance does not amount to a waiver of Our rights.


MODIFICATIONS TO OUR SERVICES
Cohorts Software Applications Inc. reserves the right to modify, suspend, or discontinue Our Services at any time for any reason with or without notice.
Cohorts Software Applications Inc. reserves the right to update and change these Terms of Use from time to time without notice. Any new features that augment or enhance the Our Services, including the release of new tools and resources, shall be subject to these Terms of Use. Should you continue to use Our Services after any such modifications have been made, this shall constitute your agreement to such modifications.

CANCELLATION AND TERMINATION
You alone are responsible for properly cancelling your Account. 
An email or phone request to cancel your Account shall result in cancellation. 
Any cancellation of your Account will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. This information cannot be recovered from Cohorts Software Applications Inc. once your account is cancelled. 

Cohorts Software Applications Inc. reserves the right to
-modify or discontinue, temporarily or permanently, Our Services (or any part thereof); and
-refuse any and all current and future use of Our Services, suspend or terminate your account (any part thereof) or use of Our Services and remove and discard any of Your Content (as hereinafter defined) in Our Services, for any reason, including if we believe that you have violated these Terms of Use.

CONTENT AND CONTENT RIGHTS
For purposes of these Terms: 
- “Content” means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through Our Services; and 
-“User Content” means any Content that users (including you) provide to be made available through Our Services. 

Content includes without limitation User Content.
Content Ownership, Responsibility, and Removal
Cohorts Software Applications Inc. does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit your User Content. Subject to the foregoing, Cohorts Software Applications Inc. and its licensors exclusively own all right, title and interest in and to Our Services and Content, including all associated intellectual property rights. You acknowledge that Our Services and Content are protected by copyright, trademark, and other laws of the United Kingdom, United States, and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying Our Services or Content.

Rights in user content granted by you:
MP365DE merely posts  User's content  with specific templated designs . 
By making any User Content available through Our Services you hereby grant to Cohorts Software Applications Inc. a non-exclusive, transferable, sublicensable, worldwide, royalty-free license to use, copy, modify, create derivative works based upon, and distribute your User Content in connection with the following limited purposes:
You are solely responsible for all your User Content. 
You represent and warrant that you own all your User Content or you have all rights that are necessary to grant us the license rights in your User Content under these Terms. You also represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through Our Services, nor any use of your User Content by Cohorts Software Applications Inc. on or through Our Services will infringe, misappropriate, or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any contract by which you are bound, or of any applicable law or regulation.
You can remove your User Content by specifically deleting it, or by following procedures outlined in our Privacy Policy.
Links to Other Websites and Content from Other Websites:
Our Services may contain links to or embedded Content or designs from third-party web sites or services that are not owned or controlled by Cohorts Software Applications Inc..
Cohorts Software Applications Inc. has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Cohorts Software Applications Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.

GENERAL CONDITIONS
Your use of Our Services, including any Content, information, or functionality contained within it, is provided “as is” and “as available” with no representations or warranties of any kind, either expressed or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. You assume total responsibility and risk for your use of Our Services.
You agree not to resell, duplicate, reproduce or exploit any part of Our Services without the express written permission of Cohorts Software Applications Inc..
You agree not to export or collect, by any electronic or other means, email addresses or other information of other users of Our Services for the purpose of sending emails or other unsolicited correspondence.
You agree not to act in a way that risks damaging, deactivating, or overloading Our Services or its infrastructure.
You agree not to distribute anything containing a computer virus or any code, file or software program intended to interrupt, destroy or limit the functionality of Our Services or its infrastructure.

DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
MP365DE (including the website and services offered) provides as is, as available without warranty of any kind and Cohorts makes no promises or guarantees. Without limiting this, Cohortsexpressly disclaim all warranties, whether express, implied or statutory, regarding Cohorts Software Applications Inc. including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement. Cohorts Software Applications Inc. further disclaims any warrant that it will meet your requirements; that it will be uninterrupted, timely, secure, or error-free; that the information provided through it is accurate, reliable or correct; that any defects or errors will be corrected; that it will be available at any particular time or location; or that it is free of viruses or other harmful components. You understand and agree that you use Cohorts Software Applications Inc. at your own discretion and risk, and that you will be solely responsible for any damages to any computer system or risk of any loss of data or inaccurate data resulting from your downloading and/or use of files, information, content or other material obtained from the use of Cohorts Software Applications Inc..
Cohorts Software Applications Inc. is not liable for damages or losses arising from your use or inability to use or otherwise arising under this agreement. Cohortswill not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages made on network or storage service.
Cohorts Software Applications Inc. and its affiliates and its sponsors are neither responsible nor liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, or other damages arising out of or relating in any way to your use of Our Services. Your sole remedy for dissatisfaction with Our Services is to stop using Our Services.
If any provision of the Terms of Use is held invalid or otherwise unenforceable, the enforceability of the remaining provisions shall not be impaired thereby.

Cohorts Software Applications Inc. makes no warranties regarding
-your ability to use Our Services, 
-your satisfaction with Our Services, 
-that Our Services will be available at all times, uninterrupted, and error-free 
-that bugs or errors in Our Services will be corrected. 

Cohorts Software Applications Inc. may, but has no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable, or violates any party’s intellectual property or these Terms of Use.
The failure of Cohorts Software Applications Inc. to exercise any right provided for herein shall not be deemed a waiver of any right hereunder. These Terms of Use set forth the entire understanding between you and Cohorts Software Applications Inc. as to Our Services and supersede any prior agreements between you and Cohorts Software Applications Inc. (including, but not limited to, prior versions of these Terms of Use).
Any questions regarding these Terms of Use should be addressed to info@cohorts.app

RELEASE AND INDEMNIFICATION
You are responsible for your use of MP365DE. 
If you harm someone else or get into a dispute with someone else, Cohorts will not be involved. 
If you have a dispute with one or more Users, you agree to release 
Cohorts and our employees, contractors, agents, officers and directors, from and against from any and all claims, 
demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses(including but not limited to attorney's fees) arising out of your use and/or access to Cohorts Software Applications Inc., including but not limited to your violation of this Agreement, provided that Cohorts Software Applications Inc.

PRIVACY
Your use of Extension is subject to MP365DE, Privacy Statement available at - https://www.cohorts.app/editor/privacy .

CHANGES TO THIS AGREEMENT
Cohorts reserve the right, at our sole discretion, to amend these Terms and Condition of Use at any time and will update these Terms and Condition of Use in the event of any such amendments.
terms

GOVERNING LAW
Unless otherwise required by law (and which shall only be applied strictly for its intended purpose), 
it is expressly agreed that the laws of British Columbia , Canada shall be the laws applicable to these Terms of Use and any litigation between you and Cohorts Software Applications Inc.related to the use of Our Services shall be submitted to the jurisdiction of a competent court in British Columbia, Canada. 
These Terms of Use will be governed by and construed in accordance with the laws British Columbia Canada, without giving effect to its conflict of law provisions or your actual state or country of residence. 
If for any reason a court of competent jurisdiction finds any provision or portion of the Terms of Use to be unenforceable, the remainder of the Terms of Use will continue in full force and effect.   
  `};

export const EditorTerms = ()=>{

return(
        <>
              <Hero className="illustration-section-01" />
              <SectionHeader data={sectionHeader} className="left-content" />

        </>
    )
}