export const blogData = [
  { title: "How do I revoke permissions?", content: "One Granted, in the Account Tab you can revoke permissions" , img: "feature-tile-icon-01.svg"},
  { title: "How do I login/ Logout?", content: "There is no Login / Logout process as this app is operating within the context of Teams" , img: "feature-tile-icon-01.svg"},
  { title: "Does the app have connnections to its own sever?", content:"The app is loaded from a separate web application but is not communicating back to the server, it operates wihin Teams only"  , img: "feature-tile-icon-01.svg"},
  { title: "Can I create my own color template?", content: "This feature is not available as of yet." , img: "feature-tile-icon-01.svg"},
  { title: "How do I request a feature to be developed?", content: "Send an email to info@cohorts.app" , img: "feature-tile-icon-01.svg"},
  { title: "How do I saev my selection color template for all new posts?", content: "This feature is not available as of yet." , img: "feature-tile-icon-01.svg"},
  { title: "My post does not show in Teams Channel.", content: "This might be because the permission expired and you need to grant permissions again." , img: "feature-tile-icon-01.svg"},
  { title: "What kind of text formatting is available?.", content: "There is no support for formatting yet. You may use very basic HTML tags like break." , img: "feature-tile-icon-01.svg"},
  { title: "Does the app support private channels?", content: "Not yet." , img: "feature-tile-icon-01.svg"},
  { title: "Can I design my own color palette?", content: "This feature is not supported as of yet." , img: "feature-tile-icon-01.svg"},
  { title: "Can I edit the new color postcard in Teams after I post it with the app?", content: "Yes, you may." , img: "feature-tile-icon-01.svg"},
 // { title: "What is the clinetId of the application as registered in Azure?", content: "clientId: 9d6b07f5-95d6-46e5-9cee-d65372dbb81c" , img: "feature-tile-icon-01.svg"},
  { title: "What permissions are required for the application?", content: `Scopes:User.Read,ChatMessage.Send,Group.ReadWrite.All` , img: "feature-tile-icon-01.svg"},
];

export const blogHeader = {
  title: "Blog of Q&A",
  paragraph:
    "Points of Clarifcation",
};
