import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import { SectionHeader } from './partials/SectionHeader';
import Image from '../elements/Image';
import { TileItem } from './partials/TileItem';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
export const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  data,
  header,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Collect Posts and Replies to one Email and Send',
    paragraph: 'Explore in a dynamic view the posts and replies within Teams and Channels you are a member of. Select to keep the ones needed for the meeting summary. Manage the selected posts in the application.  Assemble just the ones you want to an online editor. Edit the content, add any additional notes and comments. Email the curated content.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={header} className="center-content" />
          <div className={tilesClasses}>
          
          { 
            data.map(datum =>{
               return (<TileItem title={datum.title} content={datum.content} img={datum.img} productLink={datum.productLink}/>) 
              }
            )
          }
        </div>                
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

